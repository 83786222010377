html,
body {
  height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  //width: 800px;
  //height: 1280px !important;

  #app {
    background-color: $grey;
    @media (min-width: 668px) {
      height: 667px;
      width: 376px;
    }
  }
}

a {
  text-decoration: none;
}

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: 4px;
}

h5 {
  font-weight: 400;
}

p {
  line-height: 125%;
}

.mini {
  font-size: 12px;
  line-height: 1.3;
}

#app {
  width: 100%;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  margin: 0 auto;

  .main-container {
    display: flex;
    flex-grow: 1;
    //margin: 0 20px;
  }

  .start-container {
    display: flex;
    flex-grow: 1;
    #bankovci g:nth-child(1) {
      animation: levitate 5s infinite ease-in-out;
    }

    #bankovci g:nth-child(2) {
      animation: levitate 4s infinite ease-in-out;
    }

    #bankovci g:nth-child(3) {
      animation: levitate 6s infinite ease-in-out;
    }
    #bankovci g:nth-child(4) {
      animation: levitate 9s infinite ease-in-out;
    }
    #bankovci g:nth-child(5) {
      animation: levitate 7s infinite ease-in-out;
    }
    #bankovci g:nth-child(6) {
      animation: levitate 5s infinite ease-in-out;
    }
    #bankovci g:nth-child(7) {
      animation: levitate 5s infinite ease-in-out;
    }
    #bankovci g:nth-child(8) {
      animation: levitate 6s infinite ease-in-out;
    }
    #bankovci g:nth-child(9) {
      animation: levitate 6s infinite ease-in-out;
    }

    @keyframes levitate {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }

  .fixed-top-bar {
    flex-basis: 70px;
    margin: 20px;
    background: linear-gradient(
      36.65deg,
      #ffffff -94.31%,
      rgba(255, 255, 255, 0.5) 340.41%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    img {
      width: 100px;
    }
  }

  .fixed-bottom-bar {
    //flex-basis: 50px;
    margin: 0 20px;
    .logo-banka {
      height: 40px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  .btn {
    position: relative;
    z-index: 10;
    background: $gold-gradient;
    color: $white;
    border: 0px solid transparent;
    filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.25));

    &:hover {
      color: $white;
    }

    &:focus,
    &:active {
      color: $white;
      background: $gold-gradient;
      //background: $gold-gradient;
      //opacity: 0.8;
      //color: $white;
    }

    span {
      display: block;
      line-height: 1;
      font-weight: 600;
      text-transform: uppercase;
    }

    &.button-bottom {
      position: absolute;
      bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }

  &.start {
    /*     background-color: #e6dece !important;
    position: absolute;
    top: 0;
    height: 100%; */
  }
}

.narrow-container {
  width: 70%;
  margin: 0 auto;
}

// Forms

.danger {
  input {
    border-color: $red;
  }
}

input {
  font-family: $headings-font-family;
  //color: $gold;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
input::placeholder {
  color: rgba(#420b3e, 0.5) !important;
}
.user-enter {
  input {
    pointer-events: none;
    font-size: 2rem;
    color: $black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: $headings-font-family;
    border: 0;
  }
}

// ICONS
.circle-icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: $white;

  &__small {
    width: 50px;
    min-width: 50px;
    height: 50px;
  }
}

// THEMES

.shadow-wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &__bg-grey {
    background: linear-gradient(
      36.65deg,
      #ffffff -94.31%,
      rgba(255, 255, 255, 0.5) 340.41%
    );
  }
}

.theme-gold {
  .wrapper {
    background-color: rgba($gold, 0.3);
  }
}

.bg-gradient-gold {
  background: $gold-gradient;
}

.bg-gradient-gold-light {
  background: linear-gradient(
    36.65deg,
    #ffffff -94.31%,
    rgba(255, 255, 255, 0.5) 340.41%
  );
}

.theme-light {
  .wrapper {
    background-color: rgba($gold, 0.3);
  }
}

.theme-dark {
  .wrapper,
  &.wrapper {
    background-color: rgba($grey, 1);
  }
}

// Game 1
.game1,
.game2,
.game3,
.game4,
.game5,
.game6,
.game7 {
  .btn {
    background: $gold;

    &:active,
    &:focus {
      background: $gold;
    }
  }

  h2 {
    color: $gold;
  }

  .bg {
    background-color: $orange;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $orange;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $orange;
    }
  }

  &.quiz {
    .answer-section {
      .selected:not(:focus) {
        opacity: 0.5;
      }
      .react-card-flip {
        &__front {
        }

        &__back {
          //transform: rotateY(180deg);
        }

        p {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          padding: 0 13px;
          line-height: 128.571%;
        }
      }

      .answ-container.disable {
        opacity: 1;
        pointer-events: none;
      }
      button.answ-container:nth-child(1) {
        transform: rotate(-20deg) !important;
      }

      button.answ-container:nth-child(2) {
        transform: rotate(20deg) !important;
      }

      button.answ-container:last-child {
        margin-top: -15%;
      }

      .btn-answer {
        z-index: 0;
        background-color: rgba($gold, 0.3);

        .react-card-back {
          border-radius: 5px;
          background-color: rgba($gold-light, 1);
        }
        //background-color: rgba($gold-light, 1);
        &:hover,
        &:focus {
          /* border: 5px solid $orange; */
          border: 0;
          .animation__active {
            //background-color: rgba($gold-light, 1);
            //z-index: -1;
          }
        }

        &:focus {
          z-index: 10;
          //background-color: rgba($gold-light, 1);
          .react-card-back {
            //transform: scale(1.1) !important;
          }
        }

        &.wrong {
          //color: $white;
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($green, 0.4);
          }
        }

        &.wrong,
        &.correct {
          z-index: 10;
        }
      }
    }
  }
}

.quizbonus {
  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $orange;
    }

    .active {
      background-color: rgb(75, 74, 74);
    }
  }
  &.quiz {
    .question-section {
      p {
        color: $white;
      }
    }

    .answer-section {
      .btn-answer {
        height: 160px;
        background: $white;
        &:hover,
        &:focus {
          border: 0;
        }

        &.wrong {
          background-color: rgba($grey, 0.2);
          color: $white;
        }

        &.correct {
          background-color: $green;
          color: $white;
        }
      }
    }
  }

  .btn-confirm,
  .calc,
  .hint {
    display: none;
  }
}

.game1-bg-light {
  background: rgba($orange, 0.65);
}

.game1-bg-light-30 {
  background: rgba($orange, 0.2);
}

.game2-bg-light {
  background: rgba($green, 0.65);
}

.game3-bg-light {
  background: rgba($green-light, 0.65);
}

.game4-bg-light {
  background: rgba($blue, 0.65);
}

.game5-bg-light {
  background: rgba($gold, 0.65);
}

.game6-bg-light {
  background: rgba($grey, 0.65);
}

.tapping-bg-light {
  background-color: $blue;
  margin: 10px;
}

.quizbonus-bg-light {
  background-color: $green;
  margin: 10px;
}

// Animations

.float {
  animation: float 6s ease-in-out infinite;

  &__d1 {
    animation-delay: 0s;
  }
  &__d2 {
    animation-delay: 0.5s;
  }
  &__d3 {
    animation-delay: 1s;
  }
}
@keyframes float {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
}

.lock {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  z-index: 5;

  path {
    stroke: none;
    stroke-width: 1;
    stroke-dasharray: none;
    stroke-linecap: butt;
    stroke-dashoffset: 0;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    fill: rgb(0, 0, 0);
    fill-rule: nonzero;
    opacity: 1;
  }
}

.back-button {
  width: 100px;
  margin: 0 auto;
  font-weight: bold;
  background: transparent !important;
  color: #a48242 !important;
  font-size: 1.1rem;
}

.admin {
  .user-game-item {
    width: 100%;
    color: $white;
    display: block;
    font-size: 9px;
  }
  .current-game {
    min-height: 100vh;
    position: relative;
    .summary {
      background-color: $green-light;
    }
    h2 {
      font-size: 18px;
      color: $white;
    }
    h3 {
      font-size: 14px;
      color: $white;
    }

    .new-game {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .btn {
    font-family: $font-family-sans-serif;
    font-size: 15px;
  }
  .addTask {
    .form-control {
      font-size: 11px;
      border: 1px solid $gold;
      padding: 10px 15px;
      color: $grey-light;
    }
  }
}

// Meter
/* add css module styles here (optional) */

/* .test {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
} */
._1alIC {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //margin: 10px;
  border: none;
  padding: 10px;
  background-color: rgba(202, 122, 102, 0.9);
  border-radius: 0;
  display: flex;
  z-index: 9999;
  color: #fff;
  flex-direction: column;
}

._nqCmj {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 600;
  padding: 5px 0;
}

._2Qem8 {
  flex-grow: 1;
  font-size: 0.8rem;
}

.menjava-kviz {
  overflow: visible;
  .mosnja {
    cursor: pointer;
    opacity: 0.5;
    //pointer-events: all;
    transform-origin: center;

    &.selected {
      opacity: 1;
      animation: bounce 0.5s ease;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(
          -10px
        ); /* Adjust this value to change the intensity of the bounce */
      }
    }

    &.wrong,
    &.correct {
      pointer-events: none;
    }

    .wrong,
    .correct {
      opacity: 0;
      transform: scale(2);
      transform-origin: center;
      transform-box: fill-box;
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.selected.wrong {
      pointer-events: none;
      .wrong {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.selected.correct {
      .correct {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.finale {
  .countup {
    span {
      color: var(--zelena-glavna, #01452b);
      width: 44px;
      border-radius: 5px;
      opacity: 0.9;
      background: var(--be-iz-razstave, #f9f4e6);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: inline-block;
      margin: 0 5px;
      padding: 15px 0;
      text-align: center;
      -webkit-text-stroke-width: 1;
      -webkit-text-stroke-color: var(--zelena-glavna, #01452b);
      font-family: "Patrick Hand";
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px; /* 100% */
      letter-spacing: 5px;
    }

    .caption {
      color: #e7e3d7;
      -webkit-text-stroke-width: 0.5;
      -webkit-text-stroke-color: #e7e3d7;
      font-family: "Patrick Hand";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 80% */
      letter-spacing: 5px;
    }
  }

  #finale-hero {
    #konfeti {
      transform-origin: center; /* Ensures scaling happens from the center */
      animation: springEffect 0.7s ease-in-out forwards; /* Adjust time as needed */
    }

    @keyframes springEffect {
      0% {
        transform: scale(0); /* Start from scale 0 */
        opacity: 0; /* Start from hidden */
      }

      15% {
        transform: scale(0); /* Start from scale 0 */
        opacity: 0; /* Start from hidden */
      }

      50% {
        transform: scale(1.1); /* Scale up to 1.5 */
        opacity: 1; /* Fully visible */
      }
      100% {
        transform: scale(1); /* Scale back down to 1 */
      }
    }

    @keyframes moveToOrigin {
      from {
        transform: translate(0);
      }
      to {
        /* This will be filled individually per element below */
      }
    }

    #_200 {
      animation: moveToOrigin 1s ease-in-out forwards;
    }

    #_50 {
      animation: moveToOrigin 0.8s ease-in-out forwards;
    }

    #_10 {
      animation: moveToOrigin 0.6s ease-in-out forwards;
    }

    #_5 {
      animation: moveToOrigin 0.7s ease-in-out forwards;
    }

    #_5_2 {
      animation: moveToOrigin 0.9s ease-in-out forwards;
    }

    /* Now specify the starting transform for each element */
    @keyframes _200_moveToOrigin {
      from {
        transform: translate(0, 50px);
      }
      to {
        transform: translate(0);
      }
    }

    @keyframes _50_moveToOrigin {
      from {
        transform: translate(10px, 160px);
      }
      to {
        transform: translate(0);
      }
    }

    @keyframes _10_moveToOrigin {
      from {
        transform: translate(-30px, 200px);
      }
      to {
        transform: translate(0);
      }
    }

    @keyframes _5_moveToOrigin {
      from {
        transform: translate(120px, 200px);
      }
      to {
        transform: translate(0);
      }
    }

    @keyframes _5_2_moveToOrigin {
      from {
        transform: translate(80px, 80px);
      }
      to {
        transform: translate(0);
      }
    }

    /* Apply the individual keyframes to each element */
    #_200 {
      animation-name: _200_moveToOrigin;
    }

    #_50 {
      animation-name: _50_moveToOrigin;
    }

    #_10 {
      animation-name: _10_moveToOrigin;
    }

    #_5 {
      animation-name: _5_moveToOrigin;
    }

    #_5_2 {
      animation-name: _5_2_moveToOrigin;
    }
  }
}

.cestitke {
  #bankovci {
    /* Set the initial state */
    transform-origin: center; /* Ensure scaling occurs from the center */
    transform: scale(0); /* Initial scale */
    transform-box: fill-box;
    /* Apply the animation */
    animation-name: scaleUp;
    animation-delay: 0.5s;
    animation-duration: 0.5s; /* Duration of the animation */
    animation-timing-function: ease-in-out; /* Ease-in effect */
    animation-fill-mode: forwards; /* Maintain the final state after animation completes */
  }

  @keyframes scaleUp {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  #confeti {
    // Default style for all paths
    g {
      transform-origin: center;
      opacity: 0; // Start hidden
      animation-name: springEffect;
      animation-fill-mode: forwards; // Maintain the final state after animation ends
    }

    // Using a loop to apply styles to each path
    @for $i from 1 through 30 {
      // Assuming you have 5 path elements and you want unique animations for each
      g:nth-child(#{$i}) {
        animation-duration: 0.5s; // Increment duration by 0.2s for each path
        animation-delay: 0.05s * $i; // Delay increases by 0.1s for each path
      }
    }
  }

  @keyframes springEffect {
    0%,
    15% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.form-control.answer {
  background-color: rgba($gold, 0.2);
}
