// Colors

$orange: #ee968d;
$orange-light: #ee968d;
$blue: #85c1c8;
$green: #026b4e;
$green-light: #84a76a;
$green-medium: #4e9783;
$green-dark: #01452b;
$grey: #4a4f54;
$grey-light: #969696;
$gold: #a48242;
$gold-medium: #dccaa7;

$gold-light: #e6dece;
$babyblue: #aad4d9;

$red: #e6695c;

$white: #fff;

$gold-gradient: linear-gradient(180deg, #a48242 0%, #b99450 100%);

$font-family-sans-serif: "Arial";
$primary: $gold;
$secondary: $green;

$headings-font-family: "Patrick Hand", cursive;
$headings-color: $grey;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 7,
  8: $spacer * 8,
);

$theme-colors: (
  "orange": $orange,
  "orange-light": $orange-light,
  "blue": $blue,
  "green": $green,
  "green-light": $green-light,
  "green-medium": $green-medium,
  "gree-dark": $green-dark,
  "grey": $grey,
  "gold": $gold,
  "gold-medium": $gold-medium,
  "gold-light": $gold-light,
  "white": $white,
  "red": $red,
  "babyblue": $babyblue,
  "warning": $red,
);

$font-size-base: 1rem;
$headings-font-weight: 700;
$headings-line-height: 1;
$h1-font-size: 2.5rem;
$h2-font-size: 1.875rem;
$h3-font-size: 2rem;

$btn-focus-box-shadow: 0;
$btn-font-family: $headings-font-family;
$btn-font-size: 1.25rem;
$btn-padding-x: 3rem;
$btn-padding-y: 0.8rem;
//$btn-border-width: 3px;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$border-radius: 7px;
$border-radius-sm: 7px;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $blue;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-btn-font-size-sm: 0.8rem;
$input-border-color: transparent;
$input-border-width: 0;
$input-focus-border-color: $white;
$input-color: #420b3e;
$input-font-size: 1.2rem;
//$form-floating-label-opacity: 0.5;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.4rem;
$form-check-input-width: 1.5em;
$form-check-input-border: 0;
$form-check-input-border-radius: 5px;

$form-check-input-bg: $gold;
$form-check-input-width: 1em;
$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
